<template>
  <transition name="modal">
    <div v-if="!d_minimized" :class="[d_maximized ? 'full-screen-mode' : 'modal fade show modal-mask']" id="myModal" tabindex="-1" role="dialog">
      <div :class="f_calculateClass()" role="document" :style="f_calculateStyle()">
        <div class="modal-content">
          <b-row>
            <b-col sm="12" md="9">
              <slot name="header"></slot>
            </b-col>
            <b-col sm="12" md="3" v-if="!p_hideButtons">
              <b-button class="pull-right" @click="$emit('close')" title="Kapat" style="margin-right: 0px; padding: 0px; border: solid 1px #5d7d75;">
                <img src="@/icon/1890926.png" style="width: 1.5em;">
              </b-button>
              <b-button class="pull-right" @click="d_minimized = true" style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" title="Küçült">
                <img src="@/icon/45774.png" style="width: 1.5em;">
              </b-button>
              <b-button variant="secondary" class="pull-right" @click="d_maximized === true ? d_maximized = false : d_maximized = true " style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" title="Tam Ekran">
                <img src="@/icon/900293.png" style="width: 1.5em;">
              </b-button>
            </b-col>
          </b-row>
          <div class="modal-body" id="body_content" :style="'height: ' + d_height + 'px'">
            <p>
              <slot name="body">
              </slot>
            </p>
          </div>
          <div class="modal-footer" v-if="p_footer">
            <b-col> <img src="../../../public/img/favicon.png" style="width: 33px; height: 33px;"> </b-col>
            <slot name="footer">
              <button type="button" class="btn btn-primary" style="margin-right: 3px;">Kaydet</button>
              <button type="button" class="btn btn-danger" @click="$emit('close')" style="margin-right: 3px;">Kapat</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="d_minimized" style="position: fixed; bottom: 30px; right: 0px;">
      <b-button variant="warning" data-dismiss="modal" @click="d_minimized ? d_minimized = false : d_minimized = true" style="margin-right: 3px; width: 100px;" :title="$t('wdm16.6589')">
        <img src="@/icon/900293.png" style="width: 2em;">
      </b-button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    p_large: {
      required: false,
      default: false
    },
    p_width: {
      required: false,
      type: String
    },
    p_footer: {
      type: Boolean,
      required: false,
      default: true
    },
    p_hideButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      d_height: '',
      d_maximized: false,
      d_minimized: false,
      d_user: {},
      hospital_data: {},
    };
  },
  created: function () {
    this.d_height = screen.height * 60 / 100;
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {},
  methods: {
    f_calculateClass: function () {
      if (this.d_maximized) {
        return 'full-screen-mode';
      } else {
        if (this.p_large) {
          return 'modal-dialog modal-lg';
        } else {
          return 'modal-dialog';
        }
      }
    },
    f_calculateStyle: function () {
      if (this.d_maximized) {
        return 'width: 100%';
      }
      if (this.p_width) {
        if (this.p_width.indexOf('%') !== -1) {
          return 'width: ' + this.p_width.replace('%', '') + '%; height: 90%;';
        } else {
          return 'max-width: ' + this.p_width + 'px; height: 90%;';
        }
      } else {
        return 'height: 90%;';
      }
    }
  }
};

</script>

<style type="text/css">
.modal-mask {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-header {
  padding: 0.3px;
}

.modal-footer {
  padding: 0.3px;
}

#body_content::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#body_content::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#body_content::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.full-screen {
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>


