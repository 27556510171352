import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_wdmr_tree_list (data) {
    return Vue.http.post(API_BASE_URL + 'wdmr/get_wdmr_tree_list', data);
  },
  get_wdmr_tree_list_remote (data) {
    return Vue.http.post(API_BASE_URL + 'wdmr/get_wdmr_tree_list_remote', data);
  },
  save_child_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'save_child_wdmr', data);
  }
};

