<template>
  <div>
    <b-row style="margin-bottom: 10px;">
      <b-col sm="12" lg="6"> </b-col>
      <b-col sm="12" lg="6" style="text-align: right;">
        <b-button v-if="user && user.permissions.indexOf('worganization_organization_record') !== -1" size="sm" @click="f_openNewOrganizationModal()" variant="primary">+ yeni organizasyon</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="12">
        <b-form-input v-model="d_searchText" placeholder="Filtrelemek için yazınız."></b-form-input>
      </b-col>
    </b-row>
    <div v-for="(org, org_ind) in d_organizationList" v-if="f_searchOrganization(org)" @mouseover="d_hoverOrganizationIndex = org_ind" @mouseleave="d_hoverOrganizationIndex = ''">
      <b-row style="margin: 2px;">
        <b-col sm="12" lg="1">
          <div :style="org_ind % 2 === 0 ? 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: -1px -1px 2px rgba(0, 0, 0, 1); background-color: rgba(28, 214, 163, 1); background-image: linear-gradient(45deg, rgba(28, 214, 163, 1) 0%, rgba(28, 214, 163, 1) 100%); min-width: 20px; margin: 5px; min-height: 20px;' : 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: rgb(0, 0, 0, 1) -1px -1px 2px; background-color: rgba(6 181 254, 1); background-image: linear-gradient(45deg, rgb(6 181 254 / 100%) 0%, rgb(6 181 254) 100%); min-width: 20px; margin: 5px; min-height: 20px;'">
            <!-- <img src="@/icon/1544043.png" style="width: 1em;"> -->
          </div>
        </b-col>
        <b-col sm="12" lg="9" @click="f_goToThisOrganization(org.w_id)" style="cursor: pointer;">
          <div class="organization_list_item">
            <span :style="d_hoverOrganizationIndex === org_ind ? 'font-weight: bold; color: rgb(252, 0, 153, 1);' : ''">{{ org.label }}</span>
            <small style="color: blue;">[ {{ org.w_id }} ]</small>
          </div>
        </b-col>
        <b-col sm="12" lg="2" style="text-align: right;">
          <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
            <template slot="button-content">
              işlem
            </template>
            <b-dropdown-item v-if="user && user.permissions.indexOf('worganization_organization_record') !== -1" @click="f_editOrganizationUser(org_ind)"><i class="fa fa-edit"></i> düzenle </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row style="margin: 10px;" v-if="d_userOfOrganizations[org.w_id] !== undefined">
        <b-col sm="12" md="1">
        </b-col>
        <b-col sm="12" md="9">
          <template v-for="(user_item, user_item_index) in d_userOfOrganizations[org.w_id]">
            <b-row style="margin: 3px;">
              <b-col sm="12" md="12">
                <img src="@/icon/9370.png" style="width: 1em;" /> {{ user_item.label }} <small style="color: green;">[id: {{ user_item.w_id }}]</small>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </div>
    <modal v-if="show_organization_modal" @close="show_organization_modal = false" :width="'1000'">
      <h3 slot="header">Organizasyon Kayıt Ekranı</h3>
      <div slot="body">
        <template v-for="(org, org_ind) in d_organizationRecordList">
          <b-card class="mb-1" no-body bg-variant="white" style="margin: 10px;">
            <b-card-header header-bg-variant="primary" size="sm" class="p-1">
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="6">
                  <strong>{{ org.id ? org.id + ' id li organizasyon güncellemesi ' : 'yeni organizasyon' }}</strong>
                </b-col>
                <b-col sm="12" lg="6" style="text-align: right;">
                  <b-button @click="f_copyOrganizationItem(org_ind)" variant="white" size="sm">
                    <span style="color: white;">kopyala</span>
                  </b-button>
                  <b-button @click="f_deleteOrganizationItem(org_ind)" variant="warning" size="sm">
                    <span style="color: white;">sil</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row style="margin: 3px;">
              <b-col sm="12" md="3">
                <strong>Organizasyon Adı</strong>
              </b-col>
              <b-col sm="12" md="9">
                <b-form-input v-model="org.label"></b-form-input>
              </b-col>
            </b-row>
            <b-row style="margin: 3px;">
              <b-col sm="12" md="3">
                <strong>Organizasyon Hakkında</strong>
              </b-col>
              <b-col sm="12" md="9">
                <b-textarea v-model="org.about"></b-textarea>
              </b-col>
            </b-row>
            <b-row style="margin: 3px;">
              <b-col sm="12" md="3">
                <strong>Kullanıcılar</strong>
              </b-col>
              <b-col sm="12" md="9">
                <template v-for="(user_item, user_item_index) in org.user_list">
                  <b-row style="margin: 3px;">
                    <b-col sm="12" md="9">
                      {{ user_item.label }} <small style="color: green;">[id: {{ user_item.id }}]</small>
                    </b-col>
                    <b-col sm="12" md="3">
                      <b-button @click="f_removeUser(org_ind, user_item_index)" variant="warning" size="sm">sil</b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
            <b-card class="mb-1" no-body bg-variant="white" style="margin: 10px;">
              <b-card-header header-bg-variant="primary" size="sm" class="p-1">
                <strong>Kullanıcı Ara</strong>
              </b-card-header>
              <b-row style="margin: 3px;">
                <b-col sm="12" md="3">
                  <strong>Kullanıcı Ara</strong>,
                  <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" />
                </b-col>
                <b-col sm="12" md="9">
                  <b-input-group>
                    <b-form-input type="search" v-on:search="f_searchInUsers" v-model="d_userSearch.text" placeholder="arama alanı" @keydown.enter.native="ClickEnter"></b-form-input>
                    <b-input-group-append>
                      <b-button size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_deleteSearchText()" title="temizle">
                        <img src="@/icon/3934901.png" style="width: 30px;" />
                      </b-button>
                      <b-button size="lg" class="pull-right" style="padding: 0px; width: 100px; border: solid 1px #20a2ce;" variant="white" @click="f_searchInUsers()" title="ara">
                        <img src="@/icon/9873.png" style="width: 30px;" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <template v-for="(search_user_item, search_user_item_index) in d_userSearch.search_list">
                <b-row style="margin: 3px;">
                  <b-col sm="12" md="9">
                    {{ search_user_item.name }} <small style="color: blue;">[username: {{ search_user_item.username }}]</small> <small style="color: green;">[id: {{ search_user_item.id }}]</small>
                  </b-col>
                  <b-col sm="12" md="3">
                    <b-button @click="f_addToOrganizatinUsers(search_user_item, org_ind)" variant="success" size="sm">ekle</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_organizationRecord()">kaydet</button>
        <button type="button" class="btn btn-danger" @click="show_organization_modal = false">kapat</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import WdmService from '@/services/wdm';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import OrganizationService from '@/services/organization';
import moment from 'moment';
export default {
  name: 'OrganizationTeam',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect,
    Modal
  },
  props: {},
  data () {
    return {
      d_userOfOrganizations: {},
      d_loading: {
        'status': false,
      },
      d_hoverOrganizationIndex: '',
      d_userSearch: { 'text': '', 'search_list': [], 'search_count': 0 },
      d_organizationRecordList: [],
      d_searchText: '',
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_selectedOrganizationIndex: '',
      d_organization: {
        'id': 'new',
        'name': '',
        'about': '',
        'list': [],
        'tree_detail': { 'label': 'Kullanıcı Bilgileri' },
        'tree_name': { 'label': 'Kullanıcı Listesi' },
        'type': 'organization'
      },
      show_organization_modal: false,
      d_organizationList: [],
      user: {},
      d_wdm22: {}
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getOrganizationList();
    this.f_getUserOfOrganizations();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_getUserOfOrganizations: function () {
      OrganizationService.organization_user_list_get()
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_userOfOrganizations = resp.data.list;
          } else {
            alert('error: ' + resp.data.status_message);
          }
        });
    },
    f_removeUser: function (org_ind, user_item_index) {
      this.d_organizationRecordList[org_ind].user_list.splice(user_item_index, 1);
      this.$forceUpdate();
    },
    f_editOrganizationUser: function (org_index) {
      this.d_organizationRecordList = [];
      let organization_id = this.d_organizationList[org_index].w_id;
      let new_organization_item = {
        'about': '',
        'id': organization_id,
        'label': this.d_organizationList[org_index].label,
        'user_list': [],
      };
      let layer_type = 'organization';
      let w_id_list = [];
      w_id_list.push(organization_id);
      WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 1)
        .then(resp => {
          let organization_data = resp.list[0].wdmr;
          let about = '';
          try {
            about = organization_data.data.general.about.val;
          } catch (err) {}
          new_organization_item.about = about;
          let layer_type = 'organization_users';
          let w_id_list = [];
          w_id_list.push(organization_id);
          WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 0)
            .then(resp => {
              let uniq_user_list = [];
              for (let u in resp.list) {
                if (uniq_user_list.indexOf(resp.list[u].w_id) === -1) {
                  uniq_user_list.push(resp.list[u].w_id);
                  let new_user_item = {
                    'id': resp.list[u].w_id,
                    'label': resp.list[u].label,
                  };
                  new_organization_item.user_list.push(new_user_item);
                }
              }
              this.d_organizationRecordList.push(new_organization_item);
              this.show_organization_modal = true;
            }, resp => {
              console.log(resp);
            });
        }, resp => {
          console.log(resp);
        });

    },
    f_addToOrganizatinUsers: function (search_user_item, org_index) {
      let det = false;
      for (let i in this.d_organizationRecordList[org_index].user_list) {
        if (this.d_organizationRecordList[org_index].user_list[i].id === search_user_item.id) {
          det = true;
          break;
        }
      }
      if (!det) {
        let new_user_item = {
          'id': search_user_item.id,
          'label': search_user_item.name,
        };
        this.d_organizationRecordList[org_index].user_list.push(new_user_item);
      }
      this.$forceUpdate();
    },
    f_copyOrganizationItem: function (org_index) {
      let new_org_item = {};
      new_org_item.label = this.d_organizationRecordList[org_index].label;
      new_org_item.about = this.d_organizationRecordList[org_index].about;
      new_org_item.user_list = this.d_organizationRecordList[org_index].user_list;
      this.d_organizationRecordList.push(JSON.parse(JSON.stringify(new_org_item)));
      this.$forceUpdate();
    },
    f_deleteOrganizationItem: function (org_index) {
      this.d_organizationRecordList.splice(org_index, 1);
      this.$forceUpdate();
    },
    f_deleteSearchText: function () {
      this.d_userSearch.text = '';
      this.d_userSearch.search_list = [];
      this.$forceUpdate();
    },
    ClickEnter: function () {
      // console.log('event.which ', event.which);
      if (event.which === 13) {
        this.f_searchInUsers();
      }
    },
    f_searchInUsers: function () {
      this.d_loading.status = true;
      if (this.d_userSearch.text) {
        this.d_userSearch.search_list = [];
        this.d_userSearch.search_count = 0;
        let data = {
          'search_text': this.d_userSearch.text
        };
        OrganizationService.user_search(data)
          .then(resp => {
            this.d_loading.status = false;
            if (resp.data.status_code === '12000') {
              this.d_userSearch.search_list = resp.data.user_list;
              this.d_userSearch.search_count = resp.data.user_count;
            } else {
              alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
            }
          });
      }
    },
    f_organizationRecord: function () {
      if (this.d_organizationRecordList.length > 0) {
        let data = {
          'organization_list': this.d_organizationRecordList
        };
        this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        OrganizationService.organization_record(data)
          .then(resp => {
            this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '12000') {
              this.show_organization_modal = false;
              this.f_getOrganizationList();
            } else {
              alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
            }
          });
      }
    },
    f_openNewOrganizationModal: function () {
      this.d_organizationRecordList = [];
      let new_organization = {
        'label': '',
        'about': '',
        'user_list': [],
      };
      this.d_organizationRecordList.push(new_organization);
      this.show_organization_modal = true;
    },
    f_searchOrganization: function (org) {
      let res = false;
      if (this.d_searchText) {
        if (org.label.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1 || org.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        } else {
          if (this.d_userOfOrganizations[org.w_id] !== undefined && this.d_userOfOrganizations[org.w_id].length > 0) {
            for (let u_index in this.d_userOfOrganizations[org.w_id]) {
              if (this.d_userOfOrganizations[org.w_id][u_index].label.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
                res = true;
                break;
              }
            }
          }
        }
      } else {
        res = true;
      }
      return res;
    },
    f_getOrganizationList: function () {
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyonlar getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_list()
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_organizationList = resp.data.list;
          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });
    },
    f_goToThisOrganization: function (wdmr307_id) {
      this.$router.push({ path: '/operations', query: { 'organization_id': wdmr307_id } });
    },
  },
  watch: {}
};

</script>

<style>
.organization_list_item: {
  height: 100%;
  cursor: pointer;
}


/*.organization_list_item:hover {
  height: 100%;
  background: #85FFBD;
}
*/

</style>


